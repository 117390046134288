@import '../../../../global';

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
.ticker {
  width: 100%;
  background-color: #31313e;
  color: white;
  .marquee {
    width: 100%;
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0.3em 0;
    span.all-companies {
      display: inline-block;
      padding-left: 100%;
      animation: marquee 120s linear infinite;
      font-size: 1em;
      font-family: Raleway;
      font-weight: bold;
      .tickerCompany {
        display: inline-block;
        border-right: 1px solid #646464;
        margin-right: 6px;
        .company-name {
          margin-right: 6px;
        }
        .current-price {
          margin-right: 6px;
          padding: 2px 7px;
          display: inline-block;
          border-radius: 5px;
          font-family: Roboto;
          background-color: rgba(0, 0, 0, 0.3);
        }
        .percentage-change {
          margin-right: 6px;
          padding: 2px 7px;
          display: inline-block;
          border-radius: 5px;
          font-family: 'Roboto';
          color: white;
        }
        .percentage-change.positive-change {
          background-color: $success;
        }
        .percentage-change.negative-change {
          background-color: $danger;
        }
      }
    }
    span {
      &:hover {
        animation-play-state: paused;
       
      }
    }
  }
  @media screen and (max-width: $media-width) {
    font-size: 0.6em;
  }
}
