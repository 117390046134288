/* Card Container */
.card-container {
  position: relative;
  width: 300px;
  height: 450px;
  border-radius: 12px;
  overflow: hidden;
}

.card-container:hover {
    cursor: pointer;
}

/* Card Border with Gradient */
.card-border {
  position: absolute;
  inset: 1px;
  border-radius: 12px;
  background: linear-gradient(
    to right,
    rgba(252, 211, 77, 0.2),
    #1a1a1a,
    rgba(252, 211, 77, 0.2)
  );
  z-index: 0;
}

.card-border::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(
    to right,
    transparent,
    rgba(252, 211, 77, 0.2),
    transparent
  );
  animation: border-flow 1s linear infinite;
}

/* Inner Card Content */
.card-content {
  position: absolute;
  inset: 2px;
  border-radius: 12px;
  background-color: #1a1a1a;
  overflow: hidden;
  z-index: 1;
}

/* Image Area */
.image-area {
  position: relative;
  width: 100%;
  height: 60%;
  overflow: hidden;
}

.game-image {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
  transition: transform 0.5s ease;
}

.image-area:hover .game-image {
  transform: scale(1.1);
}

.image-gradient {
  position: absolute;
  inset: 0;
  background: linear-gradient(
    to top,
    #1a1a1a,
    rgba(26, 26, 26, 0.5),
    transparent
  );
}

/* Corner Decorations */
.corner-decoration {
  position: absolute;
  width: 32px;
  height: 32px;
  border: 2px solid rgba(252, 211, 77, 0.5);
}

.top-left {
  top: 12px;
  left: 12px;
  border-radius: 6px 0 0 0;
  border-right: none;
  border-bottom: none;
}

.top-right {
  top: 12px;
  right: 12px;
  border-radius: 0 6px 0 0;
  border-left: none;
  border-bottom: none;
}

/* Scanning Line Effect */
.scanning-line {
  position: absolute;
  inset: 0;
  background: linear-gradient(
    to bottom,
    transparent,
    rgba(252, 211, 77, 0.1),
    transparent
  );
  opacity: 0;
  animation: scan 2s linear infinite;
}

.image-area:hover .scanning-line {
  opacity: 1;
}

/* Content Area */
.content-area {
  position: relative;
  padding: 24px;
  height: 40%;
  background-color: #1a1a1a;
}

/* Title Section */
.title-section {
  margin-bottom: 16px;
  position: relative;
}

.game-title {
  font-family: "Rajdhani", sans-serif;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  background: linear-gradient(to right, #fbbf24, #fde68a);
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: 1px;
}

/* Rank Indicators */
.rank-indicators {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 12px;
}

.rank-box {
  position: relative;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rank-decoration {
  position: absolute;
  inset: 0;
  border: 1px solid rgba(252, 211, 77, 0.3);
  transform: rotate(45deg);
}

.rank-background {
  position: absolute;
  inset: 2px;
  background: linear-gradient(to bottom right, #1a1a1a, #333333);
  transform: rotate(45deg);
}

.rank-number {
  font-family: "Rajdhani", sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: #fbbf24;
  z-index: 1;
}

.rank-text-container {
  position: relative;
}

.rank-text {
  font-family: "Rajdhani", sans-serif;
  font-size: 14px;
  background: linear-gradient(to right, #fbbf24, #fde68a);
  -webkit-background-clip: text;
  color: transparent;
}

.rank-line {
  position: absolute;
  height: 2px;
  width: 100%;
  background: linear-gradient(
    to right,
    transparent,
    rgba(252, 211, 77, 0.2),
    transparent
  );
  animation: border-flow 2s linear infinite;
}

/* Play Button */
.play-button {
  width: 100%;
  padding: 12px 16px;
  border-radius: 6px;
  font-family: "Rajdhani", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  background: linear-gradient(to right, #1a1a1a, #333333);
  border: 1px solid rgba(252, 211, 77, 0.3);
  color: #fbbf24;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.play-button:hover {
  color: #fde68a;
  border-color: #fbbf24;
  box-shadow: 0 0 20px rgba(252, 211, 77, 0.3);
}

.play-button::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(
    to right,
    rgba(252, 211, 77, 0),
    rgba(252, 211, 77, 0.1),
    rgba(252, 211, 77, 0)
  );
  transform: translateX(-200%);
  transition: transform 1s linear;
}

.play-button:hover::before {
  transform: translateX(200%);
}

/* Tech Grid Overlay */
.tech-grid-overlay {
  position: absolute;
  inset: 0;
  opacity: 0.2;
  pointer-events: none;
  background-image: linear-gradient(transparent 1px, transparent 1px),
    linear-gradient(90deg, rgba(252, 211, 77, 0.1) 1px, transparent 1px);
  background-size: 20px 20px;
}

/* Animations */
@keyframes scan {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}

@keyframes border-flow {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 200% 0;
  }
}
