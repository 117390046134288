@import "../../../../global";

.companies-stock-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  * {
    &:not(i) {
      font-family: $font-secondary;
    }
  }
  .search-wrapper {
    width: 90%;
    input[type="search"] {
      width: 100%;
      padding: 8px 10px;
      border: none;
      border-radius: 4px;
      background-color: #12121f;
      color: white;
    }
  }
  .companies-list {
    color: white;
    margin-top: 7px;
    min-height: calc(100vh - 5rem - 4rem);
    height: 100%;
    width: 95%;
    overflow-y: auto;
    
    &::-webkit-scrollbar {
      background-color: transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: dimgrey;
      border-radius: 5px;
    }
    .c-details {
      width: 100%;
    }
  }

  @media screen and (max-width: $media-width) {
    font-size: 0.5em;
    .companies-list {
      // max-height: 60vh;
      max-height: calc(100vh - 5rem - 4rem);
      position: relative;
    }
  }
}
