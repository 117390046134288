@import "../../../global";

.dalalbull-play{
  .lc{
    max-width: 24%;
    padding-top: 2em;
  }
  .sd{
    margin-left: 10px;
  }
  .mobile-share-details{
    display: none;
  }
  @media screen and (max-width: $media-width) {
    .mobile-share-details{
      display: block;
    }
  }
}
