@import "../../../global.scss";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $primary;
  font-size: 1.1rem;
  padding-top: 0.3em;
  .item{
    width: 7.5rem;
    height: 2.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: rgb(117, 81, 196);
    border-radius: 10px;
    margin-right: 15px;
  }
  .info{
    
    font-family: $font-kryptos;
  }
  .emphasis {
    color: #dadaff;
    font-size: 1.5em;
    line-height: 1.25em;
  }
}
