@import url(https://db.onlinewebfonts.com/c/dc88de55cf527ed4caac2b617465cbd9?family=Product+Sans+Light);
@import url(https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap);

// $primary: #f15a29;
// $primary-alt: #f15a29;
// $secondary: #3f943f;
// $secondary-alt: #fff;
// $bg: #1e1e1e;
// $bg-alt: #2b2b2b;
// $play-now: #474949;

//new-colors

// $primary: #469add;
// $primary-alt:  #4d77aa;

$primary: white;
$primary-alt: #ff6565;
$secondary: #4daad1;
$secondary-alt: #fff;
$bg: #262626;
$bg-alt: #1E1E1E;
$play-now: #474949;

$danger: #e53935;
$success: #689f38;

$primary-dark: #219abe;

$media-width: 990px;

$font-primary: "Oswald", sans-serif;
$font-secondary: "Raleway", sans-serif;
$font-homePage: "Rubik", sans-serif;

$font-primary: "Product Sans", sans-serif;
$font-secondary: "Product Sans Light", sans-serif;
$font-homePage: "Product Sans Light", sans-serif;
$font-kryptos: "Space Grotesk", serif;

$font-size-mobile: 22px;
$font-size-web: 30px;

$theme1: #262626;
$theme2: #360384;
$theme3: #6E2AF3;
$theme4: #771CD1;
$theme5: #CC48E9;
$theme6: #F55CF4;