@import '../../../global';

.dalalbullPortfolio {
  .content {
    .history{
      padding-top: 2em;
      margin-left: 10px;
      margin-right: 10px;
      .h2 {
        color: $primary-alt;
        font-weight: 900;
      }
    }

    .ud{
      padding-left: 0;
      max-width: 24%;
    }

    .lc{
      max-width: 24%;
      padding-top: 2em;
      padding-left: 10px;
    }
    @media screen and (max-width: $media-width) {
      .history{
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 10px;
        max-width: 100%;
      }
      .ud{
        padding-left: 0;
        max-width: 100%;
      }
  
      .lc{
        max-width: 100%;
        padding-top: 2em;
        padding-left: 10px;
      }
    }
  }
}
