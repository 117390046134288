@import "../../global";

.main{
  background-color: black;

  .bg{
    position: absolute;
    z-index: 0;
    bottom: 0;
    max-width: 100%;
    object-fit: cover;
  }
}

// .container {
//   z-index: 50;
// }

.carouselCard{
  width: 80vw;
  height: 60vh;
  border-radius: 20px;
  cursor: pointer;
  background: rgb(209,14,85);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .rankBox{
    position: absolute;
    top:20px;
    left: 20px;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    background-color: #fff;
    color: #1A1A1A;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 24px;
    line-height: 1.2rem;
    .title{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p{
        margin: 0;
        font-size: 10px;
        font-weight: bold;
        line-height: 1rem;
      }
    }
    
  }
  .icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 180px;
    border-radius: 900px;
    background-color: #882020;
    aspect-ratio: 1/1;
  }

  .gameName{
    margin: 20px 0;
    font-size: 32px;
    color: #1A1A1A;
    font-weight: bold;
  }

  .playButton{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    background-color: #882020;

    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    font-weight: bold;
  }
}

.flexbox {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  // background: #A023BB;
}

::-webkit-scrollbar-thumb {
  background:
    #771CD1;
  height: 20px;
  width: 10px;
  border-radius: 20px;
}

.cell {
  cursor: pointer;
  // padding: 30px;
  font-weight: 800;
  font-size: 1.7em;
  padding: 1rem 0;
  // background-color: yellow;
  color: $primary-alt;
  font-family: $font-homePage;
  margin-left: 6vw;
  height: auto;

  @media screen and (max-width: $media-width) {
    // padding: 0.5rem 1rem;
    font-size: 1em;
  }
}

@media screen and (min-width:1000px) {
  .row1 {
    height: 100vh;
  }
}

@media screen and (max-width:1000px) {
  .top{
    display: none;
  }
  .bottom{
    display: none;
  }

  .play{
    display: none;
  }
  .container {
    padding-top: 10rem;
    height: 100vh;
    overflow: hidden;
    // z-index: 50;
  }
  .flexbox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 0;
    z-index: 50;
    padding-top: 1vh;
  }
  .left{
    width: 60px;
  }
  .right{
    width: 60px;
    transform: rotate(180deg);
  }
}

/* Game Grid Container */
.game-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  max-width: 80rem; /* Matches Tailwind's max-w-5xl */
  margin: 0 auto;
  width: 100%;
}

@media (min-width: 768px) {
  /* Medium screens (md) */
  .game-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  /* Large screens (lg) */
  .game-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.excel-play {
  text-align: center;
  margin-bottom: 2rem;
}

.heading {
  font-family: 'Cyber', sans-serif; /* Replace 'Cyber' with the actual font used */
  font-size: 2.5rem; /* Default size */
  font-weight: bold;
  margin-bottom: 0;
  text-transform: uppercase;
  background-clip: text;
  color: transparent;
  background-image: linear-gradient(to right, #fbbf24, #fde68a, #fbbf24);
  animation: gradient-x 3s infinite; /* Ensure the animation matches the effect */
  letter-spacing: 0.1em;
}

@media (min-width: 768px) {
  .heading {
    font-size: 3rem; /* Medium screen size */
  }
}

@media (min-width: 1024px) {
  .heading {
    font-size: 3.75rem; /* Large screen size */
  }
}

.subheading {
  color: rgba(251, 191, 36, 0.6);
  font-size: 0.75rem;
  margin-top: 0.5rem;
  font-weight: 300;
  letter-spacing: 0.2em;
}

@media (min-width: 768px) {
  .subheading {
    font-size: 0.875rem; /* Medium screen size */
  }
}

@keyframes gradient-x {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 100%;
  }
}
