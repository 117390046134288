@import '../../../../global';

.custom-modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: +3;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow-y: scroll;
  background: $bg;
  // padding: 0 1em;
  .header {
    position: relative;
    i {
      position: absolute;
      margin-left: auto;
    }
  }
}
.close {
  color: #fff;
  float: right;
  font-size: 3em;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
