@import '../../../../global';

.percentage-change {
  font-family: $font-secondary;
  padding: 7px 15px;
  display: inline-block;
  border-radius: 5px;
  margin-left: 0.4em;
}
.percentage-change.positive-change {
  background-color: $success;
}
.percentage-change.negative-change {
  background-color: $danger;
}
