@import "../../../global";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");

.big-header-bg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 0;
  height: 9vh;
}

.excel-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 22px;
  height: auto;
  .logo {
    width: 80px;
    height: auto;
  }
  img {
    width: 100px;
  }
}

.excel-logo-container-go {
  display: none;
}

.logo-container {
  display: none;
}

.userDetails {
  height: auto;
  .btn {
    color: #fbbf24;
    font-weight: bold;
    font-size: 12;
  }
  /* Button container styles */
  .button {
    all: unset;
    display: flex;
    align-items: center;
    gap: 0.5rem; /* Matches the `gap-2` class */
    border-radius: 9999px; /* Full-rounded corners for `rounded-full` */
    background: linear-gradient(to right, #fbbf24, #fde68a);    padding: 0.5rem 1rem; /* Matches `px-4 py-2` */
    font-size: 0.875rem; /* Matches `text-sm` */
    font-weight: 500; /* Matches `font-medium` */
    color: black;
    transition: background-color 0.2s ease-in-out; /* Matches `transition-colors` */
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    margin-right: 30px;
  }

  /* Button hover effect */
  .button:hover {
    background-color: #805ad5;
    cursor: pointer; /* Purple-500 */
  }

  /* Icon inside the button */
  .button .icon {
    height: 1rem; /* Matches `h-4` */
    width: 1rem; /* Matches `w-4` */
  }
  .propic {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    margin-right: 1rem;
  }
}

@media only screen and (max-width: 1000px) {
  .big-header-bg {
    justify-content: space-evenly;
    padding: 0;
  }

  .excel-logo-container {
    margin: 0;
    width: 70px;
    img {
      width: 70px;
    }
  }
  .excel-logo-container-go {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: auto;
    margin: 0;
    .logo {
      width: 80px;
      height: auto;
    }
    img {
      width: 70px;
    }
  }

  .logo-container {
    display: inline;
    img {
      width: 120px;
    }
  }

  .userDetails {
    width: 70px;
    .propic {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      margin-right: 0;
    }
  }
}
