@import "../../../../global";

.graph-userdata {
  .stockname {
    color: #f1f1fa;
    font-size: 2rem;
    margin-bottom: 50px;
  }
  z-index: -1;
  padding-top: 1em;
  background: $bg-alt;
  display: flex;
  flex-flow: column;
  height: 100%;
  .user-data {
    .row {
      .col-lg-6 {
        text-align: center;
        h1 {
          color: $secondary-alt;
          margin-bottom: 0px;
        }
        h3 {
          color: $secondary-alt;
          margin-top: 6px;
        }
      }
    }
  }
}
